import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import kuapi from './plugins/api'
import veProgress from 'vue-ellipse-progress'
import storage from './plugins/storage';
import VueGtag from "vue-gtag-next";

const requireComponent = require.context(
    // La ruta relativa de la carpeta de componentes
    './components/global/',
    // Si mirar o no en las subcarpetas
    true,
    // La expresión regular utilizada para hacer coincidir los nombres de los componentes básicos de los ficheros
    /[A-za-z]+\.vue$/
)

const app = createApp(App);
app.config.globalProperties.$filters = {
    progamDate(date) {
        const d = new Date(date);
        let ye = new Intl.DateTimeFormat('es', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('es', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('es', { day: '2-digit' }).format(d);
        return `${da} de ${mo} - ${ye}`;
    },
    currency(price) {
        const priceFixed = (price / 1000).toFixed(3);
        return priceFixed > 1 ? String(priceFixed).replace('.', ',') : price;
    }
}

app.mixin({
    computed: {
        $loading: {
            get() {
                return this.$store.state.loading;
            },
            set(loading) {
                this.$store.commit('SET_LOADING', loading);
                if (loading) {
                    document.body.className = 'modal-body-wrapper';
                } else {
                    document.body.className = '';
                }
            }
        }
    }
});

/** Set on debugging on production build? Not tested really. */
//app.config.devtools = true;

const keys = requireComponent.keys();
// For each matching file name...
for (const fileName of keys) {
    // Get the component config
    const componentConfig = requireComponent(fileName)
        // Get the PascalCase version of the component name
    const componentName = fileName
        .replace(/^\.\//, '')
        // Remove the file extension from the end
        .replace(/\.\w+$/, '')
        // Split up kebabs
        .split('-')
        // Upper case
        .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
        // Concatenated
        .join('');
    // Globally register the component
    app.component(componentName, componentConfig.default || componentConfig)
}

store.commit('SET_STORAGE', storage);

app.use(router).use(store).use(kuapi).use(veProgress).use(VueGtag, {
    property: {
        id: "G-G60QWD12WR"
    },
    useDebugger: true,
    dataLayerName: null
}).mount('#app');