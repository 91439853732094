<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10 featured">
          <img class="logo" src="../../assets/images/log-kn-u.svg" />
          <a href="/KnDocs/aviso-privacidad-knu.pdf" target="_blank"
            >AVISO DE PRIVACIDAD</a
          >
          <!-- <router-link class="footer-link" to="">CONOCER MÁS</router-link> -->
          <ul class="social">
            <li>
              <a href="https://www.facebook.com/knotion/" target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a href="https://twitter.com/knotion" target="_blank"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>
            <li>
              <a href="https://www.instagram.com/knotion/" target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCCn36-OJlxHhLeOhpHOnYbg"
                target="_blank"
                ><i class="fab fa-youtube"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row footer-bottom">
        <div class="col">
          Algunos Derechos Reservados© Knotion® S.A. de C.V. México 2021
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
.footer-bottom {
  text-align: center;
}
footer {
  width: 100%;
  margin: 0 0 0;
  color: #fefefe;
  text-align: left;
  //padding: 65px 118px 56px 152px;
  background-color: #191919;
  a,
  a:hover,
  a:visited {
    color: inherit;
    text-decoration: none;
  }

  .featured {
    display: flex;
    align-items: top;
    flex-direction: row;
  }
  .row {
    padding: 30px 0 15px;
    &:nth-child(2) {
      border-top: 1px solid #2b2b2b;
      size: 14px;
      color: #6d6d6d;
    }
  }
  .aviso-privacidad {
    margin-right: 30px;
  }

  .logo {
    max-width: 66px;
    margin-right: 100px;
  }

  .footer-link {
    font-size: 12px;
  }

  .social {
    margin: 0;
    margin-left: auto;
    li {
      list-style: none;
      display: inline-block;
      padding: 0 7px;
    }
  }
}
</style>
