import { createStore } from 'vuex';


function getBlankState() {
    return {
        api: null,
        user: {
            token: {},
            info: {}
        },
        loading: false,
        shoppingCart: [],
        ticketCart: [],
        profile: [],
        programsToBuy: {
            programs: [],
            allowToBuy: true
        },
        storage: null,
        knotionData: null,
        states: null,
        acknowledgment: null,
    }
}

export default createStore({
    state: getBlankState(),

    mutations: {
        SET_LOADING(state, val) {
            state.loading = val;
        },
        SET_STORAGE(state, storage) {
            state.storage = storage;
        },
        SET_API(state, api) {
            state.api = api;
        },
        END_SESSION(state) {
            const api = state.api;
            const storage = state.storage;
            state = Object.assign(state, getBlankState());
            state.api = api;
            state.storage = storage;
            storage.deleteSession();
        },
        SET_KNOTION_INFO(state, user) {
            state.knotionData = Object.assign({}, user);
        },
        REMOVE_CART_ITEM(state, id) {
            const elemIndex = state.shoppingCart.findIndex((item) => {
                return item.idProgramInt == id;
            });
            if (elemIndex >= 0)
                state.shoppingCart.splice(elemIndex, 1);

            state.storage.setItem('shoppingCart', state.shoppingCart);
        },
        SET_CART(state, cart) {
            state.shoppingCart = cart;
            state.storage.setItem('shoppingCart', state.shoppingCart);
        },
        ADD_CART_ITEM(state, item) {
            state.shoppingCart.push(item);
            state.storage.setItem('shoppingCart', state.shoppingCart);
        },
        SET_TOKEN(state, tokenObj) {
            state.user.token = Object.assign({ assigned_on: new Date().getTime() }, tokenObj);
            state.storage.setItem('session', tokenObj);
        },
        SET_USER_INFO(state, info) {
            state.user.info = Object.assign({}, info);
            state.storage.setItem('user_info', info);

        },
        SET_USER_PROFILE(state, profile) {
            state.profile = profile
        },
        SET_ACKNOWLEDGMENT(state, ack) {
            state.acknowledgment = ack
        },
        SET_STATES(state, states) {
            state.states = states;
        },
        SET_TICKET_CART(state, cart) {
            state.ticketCart = cart;
        }
    },
    getters: {
        isFullAuth(state) {
            return state.user.token.access_token && state.user.info.acceptedTermsAndConditions;
        },
        getToken(state) {
            return state.user.token.access_token;
        },
        getUserInfo(state) {
            return state.user.info;
        },
        isKnotion(state) {
            return !!state.user.info.idUserKnotionInt;
        },
        getUserProfile(state) {
            return state.profile;
        },
        userAcknowledgment(state) {
            return state.acknowledgment;
        }
    },
    actions: {
        async getStates({ commit, state }) {
            if (!state.states) {
                const states = await state.api.getStates();
                commit('SET_STATES', states.data);
            }
        },
        restoreSession({ commit, state }) {
            if (state.storage.restoreSession()) {
                const session = state.storage.getItem('session');
                commit('SET_TOKEN', session || {});

                const user_info = state.storage.getItem('user_info');
                commit('SET_USER_INFO', user_info || {});

                const shoppingCart = state.storage.getItem('shoppingCart');
                commit('SET_CART', shoppingCart || []);
            }
        },
        async kuAuth({ commit, state }, data) {
            let token_obj = await state.api.getTokenFromKu(data.user, data.password);
            state.storage.setUserNamespace(data.user);
            commit('SET_TOKEN', token_obj.data);
            try {
                let userData = await state.api.getUserInfo();
                commit('SET_USER_INFO', userData.data);
            } catch (error) {
                console.error(error);
            }
        },
        async getUserInfo({ commit, state }) {
            try {
                let userData = await state.api.getUserInfo();
                commit('SET_USER_INFO', userData.data);
            } catch (error) {
                console.error(error);
            }
        },
        async checkout({ commit }, data) {
            commit('SET_TICKET_CART', data)
            commit('SET_CART', [])
        }
    }
})