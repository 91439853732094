<template>
    <div class="wrapper">
        <Header :class="style"/>
        <slot></slot>
        <Footer/>
    </div>
</template>
<script>
import Footer from '@/components/sections/Footer';
import Header from '@/components/sections/Header';

export default {
    components: {
        Footer,
        Header
    },
    computed: {
        style() {
            switch(this.$route.path) {
                case '/programs':
                case '/my-programs':
                case '/ticket':
                case '/checkout':
                    return 'top-header-obscure'
                default: 
                    return 'top-header-white'
            }
        },
    }
}
</script>

<style lang="scss">
.wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: auto;
    background-image: url("../../assets/images/bg.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
}

#logo{
    width: 100px
}
</style>
