<template>
    <div class="BlackCard">
      <slot></slot>
    </div>
</template>
<style lang="scss">
.BlackCard {
  padding: 30px;
  background-color: rgba(0, 0, 0, .7);
  position: relative;
}
</style>