<template>
<div class="sep-wrapper">
    <hr class="separator" />
</div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.sep-wrapper {
    padding: 0;
    padding-top: 50px;
    position: relative;
}
.separator {
    border-top: 2px solid white;
    width: 140px;
    position: absolute;
    left: -70px;
    top: 15px;
}
</style>

