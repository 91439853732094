import axios from 'axios'
import { API_PATHS, API_URL, replaceble } from '@/lib/constants'
import store from '@/store'
import router from '@/router'

export default {
    install: (app) => {

        const kuAPI = axios.create({
            baseURL: API_URL,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        });

        // Add a request interceptor
        kuAPI.interceptors.request.use((config) => {
            /*  Validate token or refresh if it has expired
            const tokenData = store.state.user.token;
            console.log("🚀 ~ file: api.js ~ line 19 ~ kuAPI.interceptors.request.use ~ tokenData.assigned_on - new Date().getTime()", tokenData.assigned_on - new Date().getTime())
            if ( new Date().getTime() - tokenData.assigned_on < tokenData.expires_in) {
                //delete session
                store.commit('SET_TOKEN', {});
            }*/
            config.headers["Authorization"] = 'bearer ' + store.getters['getToken'];
            return config;
        });

        // Add a response interceptor
        kuAPI.interceptors.response.use((response) => {
            return response;
        }, (error) => { // request errors
            if (error.response.status == 401) {
                store.commit('END_SESSION');
                router.push('/login');
            }
            return Promise.reject(error);
        });

        const API = {
            getUserInfo() {
                return kuAPI.get(API_PATHS.USER.INFO)
            },
            saveUser(userObject) {
                return kuAPI.post(API_PATHS.USER.SAVE, userObject)
            },
            updateUser(userObject) {
                return kuAPI.put(API_PATHS.USER.SAVE, userObject)
            },
            getProgramsToBuy() {
                return kuAPI.get(API_PATHS.PROGRAMS_BUY)
            },
            getStates() {
                return kuAPI.get(API_PATHS.USER.STATES)
            },
            getCitiesByState(stateID) {
                return kuAPI.get(API_PATHS.USER.CITIES.replace(replaceble.stateID, stateID));
            },
            getInvoiceInfo() {
                return kuAPI.get(API_PATHS.USER.INVOICE)
            },
            getUserProfile() {
                return kuAPI.get(API_PATHS.USER.PROFILE)
            },
            getTopics() {
                return kuAPI.get(API_PATHS.TOPICS)
            },
            getTokenFromKu(user, password) {
                return kuAPI.post(API_PATHS.LOGIN, { userName: user, password: password })
            },
            checkoutOrder(orderData) {
                return kuAPI.post(API_PATHS.PAYMENT, orderData)
            },
            getUserTypes(type) {
                return kuAPI.get(API_PATHS.USER_TYPES.replace(replaceble.profileType, type))
            },
            acceptTermsAndConditions(agreementConditions) {
                return kuAPI.post(API_PATHS.ACCEPT_TERMS, agreementConditions)
            },
            getClientIP() {
                return axios.get('https://api.ipify.org?format=json')
            },
            getTicket(idPayment) {
                return kuAPI.get(API_PATHS.TICKET.replace(replaceble.idPayment, idPayment), {
                    responseType: 'blob'
                });
            }
        }

        var newAPI = Object.assign({}, API, { store });
        store.commit('SET_API', newAPI);
        app.config.globalProperties.$kuAPI = newAPI;
        return newAPI;

    }
}