<template>
    <!-- <div v-if="typeof error === 'string'" class="error">{{error}}</div> -->
    <Multiselect :disabled="disabled"
                 class="ku-multiselect" :placeholder="defaultText"
                 :class="{error: error}"  :caret="true"
                 :mode="modo">
                <template v-slot:caret>
                    <i class="fa fa-caret-down"></i>
                </template>
    </Multiselect>
</template>
<script>
import Multiselect from '@vueform/multiselect'

export default {
    props: ['modo','defaultText', 'disabled', 'error'],
    data() {
        return {
        }
    },
    components: {
        Multiselect
    },
    methods: {
        clearMulti(){
            console.log(this.modelValue);
            this.$emit('input', null);
        }
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.ku-multiselect {
    margin-top:10px;
    margin-bottom:20px;
    padding: 14px 24px 20px 24px;

    --ms-bg: transparent;
    --ms-color: grey;
    --ms-radius: 0px;

    --ms-tag-color: white;
    --ms-placeholder-color: white;
    --ms-dropdown-bg: black;
    --ms-option-bg-pointed:#2a617c;
    --ms-option-bg-selected: #1d5b81;
    --ms-option-bg-selected-pointed: #2c4d8a;
    --ms-option-color-pointed: black;
    --ms-placeholder-color: grey;

    --ms-tag-bg: #2a617c;

    .multiselect-search{
        height: 100%;
        padding-left: var(--ms-px,.875rem);
        color: white;
    }

    .multiselect-clear {
        color: white;
        margin-right: 10px;
        .multiselect-clear-icon {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
                &:before {
                    content: "\f00d";
                }
            }
    }

    &.error {
        --ms-border-color: red;
        --ms-tag-color: red;
        --ms-placeholder-color: red;
    }

    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;

    i {
        float: right;
        pointer-events: none;
        background-color: transparent;
        color:white;
        padding-right: 5px;
    }

    &.error i{
        color: red;
    }
}
</style>