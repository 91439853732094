<template>
    <header>
      <div class="container" v-if="!$loading">
        <div class="row">
          <div class="col">
            <img id="logo" src="../../assets/images/log-kn-u.svg">
          </div>
        </div>
      </div>
    </header>
</template>
<style lang="scss" scoped>
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@include media-breakpoint-up(sm) { 
  img {
    left: -30px;
  }
}

header {
    margin-top: 0px;
    padding-top: 30px;
}
.container{
    position: relative;
    img{
        position: absolute;
        top: 0;
    }
}

#logo {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


</style>
