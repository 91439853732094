<template>
    <div class="ku-select">
        <select v-model="selected">
            <option value="" disabled selected="selected">{{placeholder}}</option>
            <option v-for="(option, index) in options" :key="index">{{option}}</option>
        </select>
        <i class="fa fa-caret-down"></i>
    </div>
</template>
<script>
export default {
    props: ['placeholder', 'options'],
    data() {
        return {
            selected: '',
        }
    },
}
</script>
<style lang="scss">

.ku-select {
  position: relative;
  width: 100%;
  background: transparent;
}


.ku-select select {
    margin:5px;
    border:1px solid white;
    padding: 14px 200px 14px 24px;
    margin: 10px 0.5px 10px 0;
    background:transparent;
    color:white;  
    width:100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;

}

select option {
    background-color: rgba(000,000,000,.9);
}

select + i.fa {
    float: right;
    margin-top: -40px;
    margin-right: 10px;
    pointer-events: none;
    background-color: transparent;
    color:grey!important;
    padding-right: 5px;
}
</style>
