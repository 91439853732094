import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import { trackRouter } from "vue-gtag-next";

const routes = [{
        path: '/',
        name: 'Index',
        component: () =>
            import ( /* webpackChunkName: "login" */ '@/views/Login')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ( /* webpackChunkName: "login" */ '@/views/Login')
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: { requiresAuth: true },
        component: () =>
            import ( /* webpackChunkName: "login" */ '@/views/Login')
    },
    {
        path: '/terms',
        name: 'Terms',
        meta: { requiresNotAuth: true },
        component: () =>
            import ( /* webpackChunkName: "terms" */ '@/views/Terms')
    },
    {
        path: '/register',
        name: 'Registration',
        meta: { requiresNotAuth: true },
        component: () =>
            import ( /* webpackChunkName: "register" */ '@/views/Register'),
        props: route => ({ user: route.query.user })
    },
    {
        path: '/programs',
        name: 'Programs',
        meta: { requiresAuth: true },
        component: () =>
            import ( /* webpackChunkName: "programs" */ '@/views/Programs')
    },
    {
        path: '/my-programs',
        name: 'MyPrograms',
        meta: { requiresAuth: true },
        component: () =>
            import ( /* webpackChunkName: "programs" */ '@/views/MyPrograms')
    },
    {
        path: '/ticket',
        name: 'Ticket',
        meta: { requiresAuth: true },
        props: route => ({ idPayment: route.query.id }),
        component: () =>
            import ( /* webpackChunkName: "ticket" */ '@/views/Ticket')
    },
    {
        path: '/checkout',
        name: 'Checkout',
        meta: { requiresAuth: true },
        component: () =>
            import ( /* webpackChunkName: "checkout" */ '@/views/Checkout')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 }
    }
});

router.beforeEach((to, from, next) => {
    let isAuth = store.getters.isFullAuth;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (to.name == "Logout" && isAuth) {
            store.commit('END_SESSION');
            isAuth = false;
        }
        if (!isAuth) {
            return next({ name: 'Login' })
        }
    } else if (to.matched.some(record => record.meta.requiresNotAuth) && isAuth) {
        return next({ name: 'MyPrograms' })
    }
    return next()
});

trackRouter(router);

export default router