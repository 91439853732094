<template>
<div class="col-wrapper" :class="colClass">
    <div class="program-card" :class="{selected : isSelected, unlocked: unlocked}">
        <img :src="staticsUrl+program.thumbnail" class="card-img-top" alt="..." @click="changeCartStatus(program.idProgramInt)">
        <div class="card-body">
            <span class="card-title">{{program.name}}</span>
            <div class="inner-indicators">
                <span class="price" v-if="!unlocked">$ {{ $filters.currency(program.price) }} mxn</span>
                <span class="date"><i class="far fa-calendar-alt"></i>  {{ $filters.progamDate(program.startDate) }}</span>
                <span class="date"><i class="far fa-clock"></i> {{ program.durationInMonths }} meses</span>
            </div>
            <div class="description">
                <span class="card-text">{{program.description}}</span>
            </div>
            <div class="enter">
                <div v-if="program.url">
                    <Button v-if="unlocked" :disabled="!programStarted" :href="program.url" target="_blank">{{ programButtonText }}</Button>
                </div>
                <span class="show-more" @click="openResourceVisor"  v-if="program.url==''">+ Ver más</span>
                <p class="disclaimer"  v-if="program.url=='' && unlocked">* El acceso al curso se enviará al correo electrónico registrado, previo a la fecha de inicio.</p>
            </div>
        </div>
    </div>
</div>
</template>
<style lang="scss">
.col-wrapper{
    display: flex;
}
.program-card {
    margin-bottom: 20px;
    margin-top: 20px;
    border: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 0.25rem;
    &.unlocked {
        cursor: default;
    }
    .card-img-top{
        cursor:pointer;
    }
    .program-checkbox {
        position: absolute;
        right: 15px;
        top: 15px;
    }
    &.selected {
        color: #3d3d3d;
        .card-body{
            background-color: rgb(187, 187, 187);
        }
    }
    .card-body {
        background-color: #3d3d3d;
        display: flex;
        flex-direction: column;

        .card-title {
            font-family: 'Montserrat-Medium';
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
        }
        .inner-indicators {
            margin-top: 10px;
            margin-bottom: 10px;
            & > span {
                display: block;
            }
            .price {
                color: #D16B20;
                font-weight: 600;
                font-family: 'Montserrat-Bold';
                font-size: 20px;
            }
            .date {
                font-family: 'Montserrat-Medium';
                font-size:16px;
                font-weight: 500;
                font-stretch: normal;
            }
        }
        
        .description {
            font-family: 'Montserrat-Regular';
            font-size: 14px;
        }

        .enter {
            margin-top: auto;
            .show-more {
                color: #D16B20;
                font-weight: 500;
                font-family: 'Montserrat-Regular';
                font-size: 14px;
                float: right;
                cursor: pointer;
                margin-top: 15px;
            }
            .disclaimer {
                font-weight: 500;
                font-family: 'Montserrat-Regular';
                font-size: 14px;
                margin-top:50px;
                text-align: center;
            }
        }
    }
}

</style>
<script>
import {STATICS_URL} from '@/lib/constants'

export default {
    props: ['unlocked', 'program', 'colSize', 'emitEvent'],
    data() {
        return {
            staticsUrl: STATICS_URL,
        }
    },
    computed: {
        programButtonText(){
            return this.programStarted && this.program.url ? 'IR A PROGRAMA' : 'PRÓXIMAMENTE';
        },
        programStarted(){
            return this.program.startDate <= new Date().getTime();
        },
        colClass(){
            return this.colSize ? 'col-md-'+this.colSize : 'col';
        },
        isSelected() {
            return this.$store.state.shoppingCart.some((item) => item.idProgramInt == this.program.idProgramInt);
        }
    },
    methods: {
        async changeCartStatus(id) {
            if (this.unlocked) return;
            if (this.emitEvent) {
                this.$emit("selected");
                return;
            }
            if (this.isSelected) {
                this.$store.commit("REMOVE_CART_ITEM", id);
            } else {
                this.$store.commit('ADD_CART_ITEM', this.program)
            }
        },
        openResourceVisor() {
            this.$emit("openpdf", this.program.pdf )
        }
    }
}
</script>