export const API_URL = process.env.VUE_APP_API_URL;
// https://dep.knotion.com/api-dep
export const replaceble = {
    stateID: '{idState}',
    username: '{username}',
    password: '{password}',
    idPayment: '{idPayment}',
    profileType: '{profileType}'
}

export const STATICS_URL = process.env.VUE_APP_IMG_URL;

export const API_PATHS = {
    USER: {
        INFO: '/user-info',
        SAVE: '/user', // POST => Save, PUT => Update
        STATES: '/states',
        CITIES: '/state/' + replaceble.stateID + '/cities',
        INVOICE: '/invoice-info',
        PROFILE: '/profile'
    },
    TOPICS: '/topics',
    DEP_LOGIN: '/api-key/coursera/login',
    LOGIN: '/login',
    PAYMENT: '/payment',
    REFRESH_TOKEN: '/oauth/token?grant_type=refresh_token&refresh_token=',
    PROGRAMS_BUY: '/programas-to-buy',
    USER_TYPES: '/profile-type/' + replaceble.profileType + '/user-types',
    ACCEPT_TERMS: '/accept-term-and-conditions',
    TICKET: '/payment/comprobante/' + replaceble.idPayment

}