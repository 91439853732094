const STORAGE = (function() {
    const openKey = 'knUniversity';
    const lastUserKey = 'lastUser';
    var userName = openKey;
    return {
        setUserNamespace(user) {
            userName = user;
            localStorage.setItem(lastUserKey, user);
        },
        restoreSession() {
            const lastUser = localStorage.getItem(lastUserKey);
            if (lastUser) {
                this.setUserNamespace(lastUser);
            }
            return !!lastUser;
        },
        deleteSession() {
            localStorage.removeItem(userName);
            localStorage.removeItem(lastUserKey);
            userName = openKey;
        },
        getItem(key) {
            const dObj = this.getDataObj();
            return dObj[key];
        },
        setItem(key, value) {
            const dObj = this.getDataObj();
            dObj[key] = value;
            localStorage.setItem(userName, JSON.stringify(dObj));
        },
        getDataObj() {
            const uData = localStorage.getItem(userName);
            if (uData) {
                return JSON.parse(uData);
            }
            return {};
        }
    }
})();

export default STORAGE;