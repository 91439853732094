<template>
  <Wrapper>
    <div class="loading-section" v-if="$loading">
        <ve-progress
            :progress="100"
            :thickness="5"
            color="#36a9e1"
            empty-color="#324c7e"
            animation="loop 700 1000"
            fontSize="1.5rem"
            :empty-thickness="3"
            :size="180"
            font-color="white"
            dash="5"
            :loading="$loading"
            class="progress-component"
        />
    </div>
    <router-view :class="{isLoading: $loading}" />
  </Wrapper>
</template>

<style lang="scss">
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
html, body, #app {
  height: 100%;
  margin: 0;
}
#app {
  font-family: "Montserrat-Regular", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}
.isLoading {
  display: none;
}
</style>

<script>
import '@/assets/css/main.scss'
import Wrapper from '@/components/sections/Wrapper'

export default {
  components: {
    Wrapper
  },
  mounted(){
    this.$store.dispatch('restoreSession');
  }
}
</script>
