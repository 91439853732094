<template>
    <div class="form-group">
        <label v-if="$slots.default"><slot></slot></label>
        <div v-if="typeof error === 'string'" class="error">{{error}}</div>
        <i v-if="check && value" class="far fa-check-circle"></i>
        <input :type="inputType" class="ku-textfield" :class="{error: error}" v-model="value" :placeholder="placeholder" 
        :disabled="disabled" :maxlength="maxlength" @input="inputEvent" :pattern="isNumber ? '\\d*' : null">
    </div>
</template>
<script>
export default {
    computed: {
        inputType(){
            return this.isNumber ? "text" : this.type;
        },
        isNumber() {
            return this.type == "number";
        }
    },
    watch: {
        modelValue(){
            this.value = this.modelValue;
        }
    },
    mounted() {
        this.value = this.modelValue;
    },
    data() {
        return { 
            value: ''
        }
    },
    props: {
        error: {
            type: null
        },
        placeholder: {
            type: String,
            default: 'Texto'
        },
        type: {
            type: String,
            default: 'text'
        },
        check: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        maxlength: {
            type: String
        },
        modelValue: {
            type: null
        }
    },
    methods: {
        onlyNumbers(value){
            return value.replace(/\D/g,'');
        },
        inputEvent(){
            if (this.isNumber){
                this.value = this.onlyNumbers(this.value);
            }
            this.$emit('update:modelValue', this.value)
        }
    }
}
</script>
<style lang="scss" scoped>
.form-group {
    position: relative;
}
.fa-check-circle {
    color: #55d655;
    position: absolute;
    right: -40px;
    margin-top: -10px;
    top: 50%;
    font-size: 20px;
}
.error {
    color: red;
}
.ku-textfield {
    width: 100%;
    margin:5px;
    padding: 14px 24px;
    margin: 10px 0.5px 10px 0;
    box-sizing: border-box;
    //opacity:0.5;
    background:transparent;
    //-webkit-mask-image: -webkit-gradient(linear, left 90%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))
    border: solid 1px #ffffff;
    color: white;
    &.error {
        border: solid 1px red;
        color: red;
    }
    &:disabled {
        background-color: #ffffff10;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>