<template>
    <a v-if="href && !disabled" :class="btnType" :href="disabled ? '#' : href" :target="disabled ? null : target"><slot></slot></a>
    <button v-else :type="type" :disabled="disabled" :class="btnType"><div class="ku-btn-loader" v-if="loader"></div><slot></slot></button>
</template>
<script>
export default {
    props: { 
        'target': {
            type: String,
            default: ''
        },
        'href': {
            type: String,
            default: ''
        },
        'black': {
            type: Boolean,
            default: false
        },
        'disabled': {
            type: Boolean,
            default: false
        },
        'type': {
            type: String,
            default: 'button'
        },
        'loader': {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        
    },
    computed: {
        btnType() {
          let btnClass = 'ku-btn btn-light';
          if (this.disabled){
            btnClass += ' disabled ';
          }

          btnClass += this.black ? ' ku-btn-black' : ' ku-btn-white';
          return btnClass;
        }
    }
}
</script>
<style lang="scss">
.ku-btn {
    margin-top: 40px;
    display: inline-block;
    font-size: 14px;
    font-family: 'Montserrat-Semibold';
    padding: 10px 24px;
    text-transform: uppercase;
    border: none;
    text-decoration: none;
    &.disabled{
      opacity: .5;
    }
    &:hover, &:active, &:visited {
        color: initial;
    }
}
.ku-btn-white {
    @extend .ku-btn;
    background-color: white;
    color: #404040;
}
.ku-btn-black {
    @extend .ku-btn;
    background-color: transparent;
    color: white;
    &:hover, &:active, &:visited {
        color: white;
    }
    border: 2px solid white;
}


@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ku-btn-loader {
  font-size: 10px;
  margin: auto;
  text-indent: -9999em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  float:right;
  margin-left: 10px;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: #2a617c;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &:after {
    background: #ffffff;
    //background:transparent;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}


</style>
