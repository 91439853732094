<template>
    <div class="form-check" :class="classes" @click.stop>
        <input :name="inputName" class="form-check-input regular-checkbox"  type="checkbox" 
        :disabled="disabled" @input="changeValue" v-model="value">
        <label @click.stop="changeValue" :for="inputName"></label>
        <label class="form-check-label" :for="inputName" @click="changeValue">
            <slot></slot>
        </label>
    </div>
</template>
<script> 
export default {
    watch: {
        modelValue(){
            this.value = this.modelValue;
        }
    },
    mounted() {
        this.value = this.modelValue;
        this.inputName = this.name ? this.name : 'in-'+(100 * Math.random());
    },
    data() {
        return { 
            value: false,
            inputName: ''
        }
    },
    props: {
        disabled: {
            type: Boolean,
        },
        classes: {
            type: String,
            default: ''
        },
        modelValue: {
            type: Boolean
        },
        name: {
            type: String
        }
    },
    methods: {
        changeValue(){
            if (this.disabled) return;
            this.$emit('update:modelValue', !this.modelValue)
        }
    }
}
</script>
<style lang="scss" scoped>
.form-check{
    display: inline;
    vertical-align: middle;
}
.form-check-label {
    margin-left: 30px;
}
label {
	display: inline;
}

.regular-checkbox {
	display: none;
}

.regular-checkbox + label {
	background-color: #fafafa;
	border: 1px solid #cacece;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
    cursor: pointer;
}

.regular-checkbox + label:active, .regular-checkbox:checked + label:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.regular-checkbox:checked + label {
	background-color: #e9ecee;
	border: 1px solid #adb8c0;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: black;
}

.regular-checkbox:checked + label:after {
    font-family: Montserrat;
	content: '\2714';
	font-size: 14px;
	position: absolute;
	top: 0px;
	left: 3px;
	color: black;
}


.big-checkbox input + label {
	padding: 12px;
}

.big-checkbox input:checked + label:after {
	font-size: 20px;
	left: 6px;
}

</style>