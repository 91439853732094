<template>
    <div class="shopping-cart" :class="viewType">
        <div class="sc-header" :class="{icon: !title}">
            <h4 v-if="title">{{ title }}</h4>
            <img v-else class="shopping-cart-icon" src="../../assets/images/shop-icon.png" />
        </div>
        <div class="cartBody">
            <p class="empty-msg" v-if="!cartItems.length" >Haz clic sobre un programa para añadirlo a tu compra.</p>
            <div v-for="(citem, i) in cartItems" :key="i" class="item" :class="resume">
                <!-- <img class="prog-thumb" :src="citem.img" /> -->
                <img class="prog-thumb" :src="staticsUrl+imageName(citem)" />
                <div class="description" :class="resume">
                    <span class="course-name">{{ citem.name }}</span>
                    <span class="text" v-if="isFull">{{ citem.description }}</span>
                    <span class="time" v-if="isTicket"><i class="far fa-calendar-alt"></i> {{$filters.progamDate(citem.startDate)}} <i class="far fa-clock"></i>  {{ citem.durationInMonths }} meses</span>
                    <span class="price">${{ $filters.currency(citem.price) }}</span>
                </div>
                <i class="fas fa-times-circle" @click="removeItem(citem.idProgramInt)" v-if="!isTicket"></i>
            </div>
        </div>
        <div class="sc-footer">
            <span class="total-tag">Total</span>
            <span class="price">${{ $filters.currency(totalPrice) }}</span>
        </div>
    </div>  
</template>
<script>

import { STATICS_URL } from '@/lib/constants'

export default {
    data() {
        return {
            staticsUrl: STATICS_URL
        }
    },
    props: {
        viewType: {
            type: String,
            default: 'small'
        },
        title: {
            type: String,
            default: ''
        },
        resume: {
            type: String,
            default: ''
        }
    },
    methods: {
        removeItem(id){
            this.$store.commit("REMOVE_CART_ITEM", id);
        },
        imageName(citem){
            return this.isFull ? citem.thumbnail : citem.shopThumbnail;
        },
    },
    computed: {
        totalPrice(){
            return this.cartItems.reduce((acc, i) => acc + i.price, 0);
        },
        isFull(){
            return this.viewType == 'full';
        },
        isTicket(){
            return this.resume == 'ticket';
        },
        cartItems(){
            return this.$store.state[this.isTicket ? 'ticketCart' : 'shoppingCart'];
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@include media-breakpoint-up(lg) { 
    .shopping-cart {
        .item.ticket .description {
            .price {
                float:right;
                position:relative;
                top: -77.5%;
                margin-right: 5px;
            }
        }
    }
}


.shopping-cart {
    position: relative;
    // margin: 10px;
    margin-top: 20px;
    border: 0px;
    background-color: #3d3d3d;
    &:not(.full) {
        .cartBody {
            position: relative;
            z-index: 10;
            max-height: 40vh;
            overflow-y: auto;
        }
    }
    .sc-header.icon {
        text-align: right;
    }
    .empty-msg{
        padding: 15px;
    }
    .prog-thumb{
        width: 60px;
        height: 60px;
        object-fit: cover;
        object-position: 50%;
    }
    .price {
        color: #D16B20;
    }
    
    .sc-header {
        text-align: center;
        margin: 0;
        padding: 15px 50px;
        border-bottom: 2px solid #292929;
        padding-right: 15px;
        .shopping-cart-icon{
            max-width: 40px;
        }
    }
    h4 {
        margin:0;
        font-size: 18px;
    }

    .item {
        display: flex;
        flex-direction: row;
        border-top: 2px solid #292929;

        .description{
            line-height: 14px;
            padding: 0 15px;
            font-size: 12px;

            span {
                display: block;
            }
            .price {
                margin-top: 10px;
            }
            .text {
                // font-family: 'Montserrat-ExtraLight';
                width: 90%;
                font-size: 12px;
                opacity: .8;
                padding-right: 25px;
            }
            .time {
                font-family: 'Montserrat-Regular';
                font-size: 12px;    
                font-weight: 500;
            }

        }

        &:nth-child(1){
            border-top: none;
        }

        padding: 20px 15px 25px;
        
        i {
            margin-top: 4px;
            margin-left: auto;
            color: #777777;
            cursor: pointer;
        }
    }

    .sc-footer {
        border-top: 2px solid #292929;
        padding: 10px;
        font-size: 18px;

        span:last-child {
            float: right;
        }   
    }

    &.full {
        h4 {
            font-size: 24px;
            text-align: left;
        }
        .description, .sc-footer {
            line-height: 18px;
            font-size: 16px;
        }
        .sc-footer{
            text-align: right;
        }
        .total-tag {
            margin-right: 20px;
        }
        .sc-footer, .item{
            padding: 25px 50px;
            &.ticket {
                padding: 25px 25px;
            }
        }
        .prog-thumb{
            width: 80px;
            height: 80px;
        }
    }
}

</style>